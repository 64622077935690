import React from "react";
import { FormattedMessage } from "react-intl.macro";
import logo from "../assets/img/smile-glyph.svg";

const SmileBrandingFooter = ({ showSmileBranding }) => {
  return (
    showSmileBranding && (
      <p className="caption text-center pt-1 mb-3">
        <img
          src={logo}
          className="me-0_5"
          alt="Smile logo"
          loading="lazy"
          height="16px"
          width="16px"
        />
        <FormattedMessage
          id="brand-msg"
          defaultMessage="We reward with {brand}"
          values={{ brand: "Smile" }}
        />
      </p>
    )
  );
};

export default SmileBrandingFooter;
