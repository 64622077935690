import React from "react";
import "../assets/scss/components/Checkbox.scss";

const Checkbox = ({
  id,
  text,
  subtext,
  isChecked = false,
  onClick,
  className,
  ...rest
}) => (
  <div className={`Checkbox ${className}`} {...rest}>
    <label className="check p-1_25">
      <input
        type="checkbox"
        checked={isChecked}
        onChange={onClick}
        name={id} />
      <span className="checkmark" />
    </label>
    <article
      className="description"
      onClick={(e) => e.currentTarget.parentElement.querySelector('input').click()}
    >
      <h3 className="mb-0_25 email-group-name">
        {text}
      </h3>
      <p className="mb-0">{subtext}</p>
    </article>
  </div>
);

export default Checkbox;
