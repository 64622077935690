import React from "react";
import { Button, Spinner } from "reactstrap";
import { FormattedMessage } from "react-intl.macro";

const SaveButton = ({ loading, disabled, onClick }) => {
  return (
    <Button
      className="mb-1"
      color="primary"
      onClick={onClick}
      disabled={disabled}
    >
      <FormattedMessage id="save-btn" defaultMessage="Save preferences" />
      <Spinner
        size="sm"
        color="light"
        className={`spinner ms-0_5 ${loading ? "show" : "hidden"}`}
        children={null}
      />
    </Button>
  );
};

export default SaveButton;
